import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, NgModule } from '@angular/core';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i4 from '@ngx-formly/core';
import { FieldType, FormlyModule } from '@ngx-formly/core';
import * as i2 from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import * as i3 from 'primeng/inputtext';
import { InputTextModule } from 'primeng/inputtext';
import { FormlyFormFieldModule } from '@ngx-formly/primeng/form-field';
function FormlyFieldInput_input_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "input", 2);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("type", ctx_r0.props.type || "text")("formControl", ctx_r0.formControl)("formlyAttributes", ctx_r0.field);
  }
}
function FormlyFieldInput_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "input", 3);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("formControl", ctx_r0.formControl)("formlyAttributes", ctx_r0.field);
  }
}
class FormlyFieldInput extends FieldType {}
FormlyFieldInput.ɵfac = /* @__PURE__ */(() => {
  let ɵFormlyFieldInput_BaseFactory;
  return function FormlyFieldInput_Factory(t) {
    return (ɵFormlyFieldInput_BaseFactory || (ɵFormlyFieldInput_BaseFactory = i0.ɵɵgetInheritedFactory(FormlyFieldInput)))(t || FormlyFieldInput);
  };
})();
FormlyFieldInput.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: FormlyFieldInput,
  selectors: [["formly-field-primeng-input"]],
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 3,
  vars: 2,
  consts: [["numberTmp", ""], ["pInputText", "", 3, "type", "formControl", "formlyAttributes", 4, "ngIf", "ngIfElse"], ["pInputText", "", 3, "type", "formControl", "formlyAttributes"], ["type", "number", "pInputText", "", 3, "formControl", "formlyAttributes"]],
  template: function FormlyFieldInput_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, FormlyFieldInput_input_0_Template, 1, 3, "input", 1)(1, FormlyFieldInput_ng_template_1_Template, 1, 2, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
    if (rf & 2) {
      const numberTmp_r2 = i0.ɵɵreference(2);
      i0.ɵɵproperty("ngIf", ctx.props.type !== "number")("ngIfElse", numberTmp_r2);
    }
  },
  dependencies: [i1.NgIf, i2.DefaultValueAccessor, i3.InputText, i2.NgControlStatus, i2.FormControlDirective, i4.ɵFormlyAttributes, i2.NumberValueAccessor],
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormlyFieldInput, [{
    type: Component,
    args: [{
      selector: 'formly-field-primeng-input',
      template: `
    <input
      *ngIf="props.type !== 'number'; else numberTmp"
      pInputText
      [type]="props.type || 'text'"
      [formControl]="formControl"
      [formlyAttributes]="field"
    />
    <ng-template #numberTmp>
      <input type="number" pInputText [formControl]="formControl" [formlyAttributes]="field" />
    </ng-template>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, null);
})();
class FormlyInputModule {}
FormlyInputModule.ɵfac = function FormlyInputModule_Factory(t) {
  return new (t || FormlyInputModule)();
};
FormlyInputModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: FormlyInputModule
});
FormlyInputModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, ReactiveFormsModule, InputTextModule, FormlyFormFieldModule, FormlyModule.forChild({
    types: [{
      name: 'input',
      component: FormlyFieldInput,
      wrappers: ['form-field']
    }, {
      name: 'string',
      extends: 'input'
    }, {
      name: 'number',
      extends: 'input',
      defaultOptions: {
        props: {
          type: 'number'
        }
      }
    }, {
      name: 'integer',
      extends: 'input',
      defaultOptions: {
        props: {
          type: 'number'
        }
      }
    }]
  })]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormlyInputModule, [{
    type: NgModule,
    args: [{
      declarations: [FormlyFieldInput],
      imports: [CommonModule, ReactiveFormsModule, InputTextModule, FormlyFormFieldModule, FormlyModule.forChild({
        types: [{
          name: 'input',
          component: FormlyFieldInput,
          wrappers: ['form-field']
        }, {
          name: 'string',
          extends: 'input'
        }, {
          name: 'number',
          extends: 'input',
          defaultOptions: {
            props: {
              type: 'number'
            }
          }
        }, {
          name: 'integer',
          extends: 'input',
          defaultOptions: {
            props: {
              type: 'number'
            }
          }
        }]
      })]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { FormlyFieldInput, FormlyInputModule };
