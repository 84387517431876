import * as i0 from '@angular/core';
import { NgModule } from '@angular/core';
import { FormlyFormFieldModule } from '@ngx-formly/primeng/form-field';
import { FormlyInputModule } from '@ngx-formly/primeng/input';
import { FormlyTextAreaModule } from '@ngx-formly/primeng/textarea';
import { FormlyRadioModule } from '@ngx-formly/primeng/radio';
import { FormlyCheckboxModule } from '@ngx-formly/primeng/checkbox';
import { FormlySelectModule } from '@ngx-formly/primeng/select';
class FormlyPrimeNGModule {}
FormlyPrimeNGModule.ɵfac = function FormlyPrimeNGModule_Factory(t) {
  return new (t || FormlyPrimeNGModule)();
};
FormlyPrimeNGModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: FormlyPrimeNGModule
});
FormlyPrimeNGModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[FormlyFormFieldModule, FormlyInputModule, FormlyTextAreaModule, FormlyRadioModule, FormlyCheckboxModule, FormlySelectModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormlyPrimeNGModule, [{
    type: NgModule,
    args: [{
      imports: [FormlyFormFieldModule, FormlyInputModule, FormlyTextAreaModule, FormlyRadioModule, FormlyCheckboxModule, FormlySelectModule]
    }]
  }], null, null);
})();

/*
 * Public API Surface of primeng
 */

/**
 * Generated bundle index. Do not edit.
 */

export { FormlyPrimeNGModule };
