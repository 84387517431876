// Angular
import { Component, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
// Third Party
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'caloudi-survey-form-wrapper',
  templateUrl: './survey-form-wrapper.component.html',
  styleUrls: ['./survey-form-wrapper.component.sass'],
  encapsulation: ViewEncapsulation.None,
})
export class SurveyFormWrapperComponent extends FieldWrapper {
  @ViewChild('fieldComponent', { read: ViewContainerRef })
  public override fieldComponent: ViewContainerRef;

  constructor() {
    super();
  }
}
