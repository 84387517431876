import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, NgModule } from '@angular/core';
import * as i4 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i2 from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import * as i3 from '@ngx-formly/core';
import { FieldType, FormlyModule } from '@ngx-formly/core';
import * as i1 from 'primeng/dropdown';
import { DropdownModule } from 'primeng/dropdown';
import * as i5 from '@ngx-formly/core/select';
import { FormlySelectModule as FormlySelectModule$1 } from '@ngx-formly/core/select';
import { FormlyFormFieldModule } from '@ngx-formly/primeng/form-field';
class FormlyFieldSelect extends FieldType {}
FormlyFieldSelect.ɵfac = /* @__PURE__ */(() => {
  let ɵFormlyFieldSelect_BaseFactory;
  return function FormlyFieldSelect_Factory(t) {
    return (ɵFormlyFieldSelect_BaseFactory || (ɵFormlyFieldSelect_BaseFactory = i0.ɵɵgetInheritedFactory(FormlyFieldSelect)))(t || FormlyFieldSelect);
  };
})();
FormlyFieldSelect.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: FormlyFieldSelect,
  selectors: [["formly-field-primeng-select"]],
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 3,
  vars: 12,
  consts: [[3, "onChange", "placeholder", "options", "formControl", "formlyAttributes", "showClear", "appendTo", "filter"]],
  template: function FormlyFieldSelect_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "p-dropdown", 0);
      i0.ɵɵpipe(1, "formlySelectOptions");
      i0.ɵɵpipe(2, "async");
      i0.ɵɵlistener("onChange", function FormlyFieldSelect_Template_p_dropdown_onChange_0_listener($event) {
        return ctx.props.change && ctx.props.change(ctx.field, $event);
      });
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵproperty("placeholder", ctx.props.placeholder)("options", i0.ɵɵpipeBind1(2, 10, i0.ɵɵpipeBind2(1, 7, ctx.props.options, ctx.field)))("formControl", ctx.formControl)("formlyAttributes", ctx.field)("showClear", !ctx.props.required)("appendTo", ctx.props.appendTo)("filter", ctx.props.filter);
    }
  },
  dependencies: [i1.Dropdown, i2.NgControlStatus, i2.FormControlDirective, i3.ɵFormlyAttributes, i4.AsyncPipe, i5.FormlySelectOptionsPipe],
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormlyFieldSelect, [{
    type: Component,
    args: [{
      selector: 'formly-field-primeng-select',
      template: `
    <p-dropdown
      [placeholder]="props.placeholder"
      [options]="props.options | formlySelectOptions : field | async"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [showClear]="!props.required"
      [appendTo]="props.appendTo"
      [filter]="props.filter"
      (onChange)="props.change && props.change(field, $event)"
    >
    </p-dropdown>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, null);
})();
class FormlySelectModule {}
FormlySelectModule.ɵfac = function FormlySelectModule_Factory(t) {
  return new (t || FormlySelectModule)();
};
FormlySelectModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: FormlySelectModule
});
FormlySelectModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, ReactiveFormsModule, DropdownModule, FormlyFormFieldModule, FormlySelectModule$1, FormlyModule.forChild({
    types: [{
      name: 'select',
      component: FormlyFieldSelect,
      wrappers: ['form-field']
    }, {
      name: 'enum',
      extends: 'select'
    }]
  })]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormlySelectModule, [{
    type: NgModule,
    args: [{
      declarations: [FormlyFieldSelect],
      imports: [CommonModule, ReactiveFormsModule, DropdownModule, FormlyFormFieldModule, FormlySelectModule$1, FormlyModule.forChild({
        types: [{
          name: 'select',
          component: FormlyFieldSelect,
          wrappers: ['form-field']
        }, {
          name: 'enum',
          extends: 'select'
        }]
      })]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { FormlyFieldSelect, FormlySelectModule };
