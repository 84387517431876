<ng-template #fieldTypeTemplate>
  <div
    *ngFor="let option of props.options | formlySelectOptions: field | async; index as i"
    class="form-check"
    [ngClass]="{
      'form-check-inline': props.formCheck === 'inline' || props.formCheck === 'inline-switch',
      'form-switch': props.formCheck === 'switch' || props.formCheck === 'inline-switch',
    }">
    <input
      type="checkbox"
      [id]="id + '_' + i"
      class="form-check-input"
      [value]="option.value"
      [checked]="isChecked(option.value)"
      [formlyAttributes]="field"
      [disabled]="formControl.disabled || option.disabled"
      (change)="onChange(option.value, $any($event.target).checked)" />
    <label class="form-check-label" [for]="id + '_' + i">
      {{ option.label }}
    </label>
    <ng-container *ngIf="option.label === 'Other'">
      <span class="other_checkobx">
        <input
          type="checkbox"
          [id]="id + '_' + i"
          class="form-check-input"
          [value]="option.value"
          [checked]="isChecked(option.value)"
          [formlyAttributes]="field"
          [disabled]="formControl.disabled || option.disabled"
          (change)="otherBoxChecked(option.value, $any($event.target).checked)" />
        <label class="form-check-label" [for]="id + '_' + i">
          :
          <input type="text" #otherInput [(ngModel)]="option.value" (keyup)="onKeySearch($event)" />
        </label>
      </span>
    </ng-container>
  </div>
</ng-template>
