// Angular
import { NgModule } from '@angular/core';
// Caloudi
import { CaloudiFormlyComponent } from '../caloudi-formly/caloudi-formly.component';
import * as Component from './index';
// Third Party
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { FormlyModule } from '@ngx-formly/core';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { FormlyPrimeNGModule } from '@ngx-formly/primeng';
import { PrimengModule } from '@primeng';

const MODULES = [FormlyModule, FormlyBootstrapModule, FormlyPrimeNGModule, FormlySelectModule];

const COMPONENTS = [
  Component.MultiCheckboxOtherComponent,
  Component.SurveyFormWrapperComponent,
  CaloudiFormlyComponent,
];

@NgModule({
  imports: [PrimengModule, MODULES],
  declarations: [COMPONENTS],
  providers: [],
  exports: [COMPONENTS],
})
export class CustomFormlyModule {}
