import * as i0 from '@angular/core';
import { Component, NgModule } from '@angular/core';
import * as i2 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i1 from '@ngx-formly/core';
import { FieldWrapper, FormlyModule } from '@ngx-formly/core';
import { ReactiveFormsModule } from '@angular/forms';
function FormlyWrapperFormField_label_1_span_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 6);
    i0.ɵɵtext(1, "*");
    i0.ɵɵelementEnd();
  }
}
function FormlyWrapperFormField_label_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "label", 4);
    i0.ɵɵtext(1);
    i0.ɵɵtemplate(2, FormlyWrapperFormField_label_1_span_2_Template, 2, 0, "span", 5);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("for", ctx_r0.id);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", ctx_r0.props.label, " ");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.props.required && ctx_r0.props.hideRequiredMarker !== true);
  }
}
function FormlyWrapperFormField_small_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "small", 7);
    i0.ɵɵelement(1, "formly-validation-message", 8);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("field", ctx_r0.field);
  }
}
class FormlyWrapperFormField extends FieldWrapper {}
FormlyWrapperFormField.ɵfac = /* @__PURE__ */(() => {
  let ɵFormlyWrapperFormField_BaseFactory;
  return function FormlyWrapperFormField_Factory(t) {
    return (ɵFormlyWrapperFormField_BaseFactory || (ɵFormlyWrapperFormField_BaseFactory = i0.ɵɵgetInheritedFactory(FormlyWrapperFormField)))(t || FormlyWrapperFormField);
  };
})();
FormlyWrapperFormField.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: FormlyWrapperFormField,
  selectors: [["formly-wrapper-primeng-form-field"]],
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 5,
  vars: 2,
  consts: [["fieldComponent", ""], [1, "p-field"], [3, "for", 4, "ngIf"], ["class", "p-error", 4, "ngIf"], [3, "for"], ["aria-hidden", "true", 4, "ngIf"], ["aria-hidden", "true"], [1, "p-error"], [1, "ui-message-text", 3, "field"]],
  template: function FormlyWrapperFormField_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "div", 1);
      i0.ɵɵtemplate(1, FormlyWrapperFormField_label_1_Template, 3, 3, "label", 2);
      i0.ɵɵelementContainer(2, null, 0);
      i0.ɵɵtemplate(4, FormlyWrapperFormField_small_4_Template, 2, 1, "small", 3);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.props.label && ctx.props.hideLabel !== true);
      i0.ɵɵadvance(3);
      i0.ɵɵproperty("ngIf", ctx.showError);
    }
  },
  dependencies: [i1.ɵFormlyValidationMessage, i2.NgIf],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormlyWrapperFormField, [{
    type: Component,
    args: [{
      selector: 'formly-wrapper-primeng-form-field',
      template: `
    <div class="p-field">
      <label *ngIf="props.label && props.hideLabel !== true" [for]="id">
        {{ props.label }}
        <span *ngIf="props.required && props.hideRequiredMarker !== true" aria-hidden="true">*</span>
      </label>
      <ng-container #fieldComponent></ng-container>

      <small *ngIf="showError" class="p-error">
        <formly-validation-message class="ui-message-text" [field]="field"></formly-validation-message>
      </small>
    </div>
  `
    }]
  }], null, null);
})();
class FormlyFormFieldModule {}
FormlyFormFieldModule.ɵfac = function FormlyFormFieldModule_Factory(t) {
  return new (t || FormlyFormFieldModule)();
};
FormlyFormFieldModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: FormlyFormFieldModule
});
FormlyFormFieldModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, ReactiveFormsModule, FormlyModule.forChild({
    wrappers: [{
      name: 'form-field',
      component: FormlyWrapperFormField
    }]
  })]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormlyFormFieldModule, [{
    type: NgModule,
    args: [{
      declarations: [FormlyWrapperFormField],
      imports: [CommonModule, ReactiveFormsModule, FormlyModule.forChild({
        wrappers: [{
          name: 'form-field',
          component: FormlyWrapperFormField
        }]
      })]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { FormlyFormFieldModule };
