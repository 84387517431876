<div class="form_container">
  <div class="form_label">
    <span class="label">
      {{ to.label }}
    </span>
    <span class="required">
      {{ to.required ? '*' : '' }}
    </span>
  </div>
  <div class="form_body">
    <ng-container #fieldComponent></ng-container>
  </div>
  <div class="message">
    <div [hidden]="!showError" class="invalid-feedback" [style.display]="'block'">
      <formly-validation-message [field]="field"></formly-validation-message>
    </div>
    <small *ngIf="to.description" class="form-text text-muted">{{ to.description }}</small>
  </div>
</div>
