import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as i3 from '@ngx-formly/core';
import { FieldType, FormlyModule } from '@ngx-formly/core';
import * as i1 from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import * as i2 from 'primeng/inputtextarea';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { FormlyFormFieldModule } from '@ngx-formly/primeng/form-field';
class FormlyFieldTextArea extends FieldType {}
FormlyFieldTextArea.ɵfac = /* @__PURE__ */(() => {
  let ɵFormlyFieldTextArea_BaseFactory;
  return function FormlyFieldTextArea_Factory(t) {
    return (ɵFormlyFieldTextArea_BaseFactory || (ɵFormlyFieldTextArea_BaseFactory = i0.ɵɵgetInheritedFactory(FormlyFieldTextArea)))(t || FormlyFieldTextArea);
  };
})();
FormlyFieldTextArea.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: FormlyFieldTextArea,
  selectors: [["formly-field-primeng-textarea"]],
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 1,
  vars: 2,
  consts: [["pInputTextarea", "", 3, "formControl", "formlyAttributes"]],
  template: function FormlyFieldTextArea_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelement(0, "textarea", 0);
    }
    if (rf & 2) {
      i0.ɵɵproperty("formControl", ctx.formControl)("formlyAttributes", ctx.field);
    }
  },
  dependencies: [i1.DefaultValueAccessor, i2.InputTextarea, i1.NgControlStatus, i1.FormControlDirective, i3.ɵFormlyAttributes],
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormlyFieldTextArea, [{
    type: Component,
    args: [{
      selector: 'formly-field-primeng-textarea',
      template: ` <textarea [formControl]="formControl" [formlyAttributes]="field" pInputTextarea></textarea> `,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, null);
})();
class FormlyTextAreaModule {}
FormlyTextAreaModule.ɵfac = function FormlyTextAreaModule_Factory(t) {
  return new (t || FormlyTextAreaModule)();
};
FormlyTextAreaModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: FormlyTextAreaModule
});
FormlyTextAreaModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, ReactiveFormsModule, InputTextareaModule, FormlyFormFieldModule, FormlyModule.forChild({
    types: [{
      name: 'textarea',
      component: FormlyFieldTextArea,
      wrappers: ['form-field']
    }]
  })]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FormlyTextAreaModule, [{
    type: NgModule,
    args: [{
      declarations: [FormlyFieldTextArea],
      imports: [CommonModule, ReactiveFormsModule, InputTextareaModule, FormlyFormFieldModule, FormlyModule.forChild({
        types: [{
          name: 'textarea',
          component: FormlyFieldTextArea,
          wrappers: ['form-field']
        }]
      })]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { FormlyFieldTextArea, FormlyTextAreaModule };
