// Angular
import { ChangeDetectionStrategy, Component, ElementRef, Type, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
// Third Party
import { FieldType, FormlyFieldProps } from '@ngx-formly/bootstrap/form-field';
import { FormlyFieldMultiCheckbox } from '@ngx-formly/bootstrap/multicheckbox';
import { FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  selector: 'caloudi-multi-checkbox-other',
  templateUrl: './multi-checkbox-other.component.html',
  styleUrls: ['./multi-checkbox-other.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiCheckboxOtherComponent extends FieldType<FieldTypeConfig<MultiCheckboxProps>> {
  public override defaultOptions = <Partial<FieldTypeConfig<MultiCheckboxProps>>>{
    props: {
      formCheck: 'default', // 'default' | 'inline' | 'switch' | 'inline-switch'
    },
  };

  @ViewChild('otherInput') private readonly otherInput: ElementRef<HTMLInputElement>;

  public otherChecked: boolean;
  public timeout: NodeJS.Timeout;

  public otherBoxChecked(value: string, checked: boolean): void {
    this.otherChecked = checked;
    if (!checked) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      this.otherInput.nativeElement.value = '';
      this.onChange(value, checked);
    }
  }

  public onKeySearch(event: KeyboardEvent): void {
    clearTimeout(this.timeout);
    // var $this = this;
    this.timeout = setTimeout(() => {
      if (event.keyCode != 13) {
        this.onChange((<HTMLInputElement>event.target).value, this.otherChecked);
      }
    }, 1000);
  }

  public onChange(value: string, checked: boolean): void {
    this.formControl.markAsDirty();
    if (this.props.type === 'array') {
      this.formControl.patchValue(
        checked
          ? [...((<FormControl<string[]>>this.formControl).value || []), value]
          : [...((<FormControl<string[]>>this.formControl).value || [])].filter(o => o !== value)
      );
    } else {
      this.formControl.patchValue({ ...this.formControl.value, [value]: checked });
    }
    this.formControl.markAsTouched();
  }

  public isChecked(optionValue: string): boolean {
    const value: string[] = (<FormControl<string[]>>this.formControl).value;
    // console.log(value);
    const result: boolean =
      value && (this.props.type === 'array' ? value.includes(optionValue) : (value[optionValue] as boolean));
    return result;
  }
}

interface MultiCheckboxProps extends FormlyFieldProps {
  formCheck: 'default' | 'inline-switch' | 'inline' | 'switch';
}

export interface FormlyMultiCheckboxFieldConfig extends FormlyFieldConfig<MultiCheckboxProps> {
  type: Type<FormlyFieldMultiCheckbox> | 'multicheckbox';
}
